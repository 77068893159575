/**
 * @method GET
 * @description получаем слайдеры
 * */

export interface IApiContentAnnouncementSliderItem {
  id: string,
  imageLandscape: {
    id: string,
    path: string,
  },
  video: {
    id: string,
    alt: string,
    title: string,
    path: string,
  } | null,
  imagePortrait: string,
  timestampStart: number,
  timestampEnd: number,
  text: string,
  duration: number,
  timer: number,
  timerTimestamp: number,
  timerLabel: string,
  header: string,
}

export default async function apiContentAnnouncementSlider () {
  const endpoint = `/content/v1/announcement-slider`

  return await useAsyncQuery(async ({ axios, path }) => {
    return await axios.get(path + endpoint)
  })
}
