<template>
  <div class="ui-banner ui-banner-skeleton">
    <div class="ui-banner__swiper">
      <div class="ui-banner__slide ui-banner-skeleton__slide">
        <div class="ui-banner__slide-wrapper ui-banner-skeleton__slide-wrapper">
          <div class="ui-banner-skeleton__title" />
          <div class="ui-banner-skeleton__subtitle-wrapper">
            <div class="ui-banner-skeleton__subtitle-item" />
            <div class="ui-banner-skeleton__subtitle-item" />
          </div>
        </div>
        <div class="ui-banner__img ui-banner-skeleton__img"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss">
.ui-banner-skeleton {
  &__slide {
    position: relative;
  }

  &__img.ui-banner__img {
    max-width: 100%;
    background: map-get($grayPalette, 'color_28');
    border-radius: 10rem;
  }

  &__slide-wrapper.ui-banner__slide-wrapper {
    padding: 24rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    inset: 0;
  }

  &__title {
    width: 28.66%;
    height: 32rem;
    background: map-get($grayPalette, 'color_36');
    border-radius: 20rem;
  }

  &__subtitle-wrapper {
    margin-top: 12rem;
    width: 100%;
    display: flex;
    gap: 0 12rem;
  }

  &__subtitle-item {
    width: 13.89%;
    height: 20rem;
    background: map-get($grayPalette, 'color_36');
    border-radius: 20rem;

    &:nth-child(2) {
      width: 7.58%
    }
  }
}
</style>
