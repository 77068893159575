<template>
  <div>
    <ui-banner-skeleton v-if="announcementSlider.pending.value || isForceHide" />
    <div v-else-if="!isForceHide && banners.length" class="ui-banner" data-testid="element_000287">
      <Swiper
        :modules="[SwiperAutoplay, SwiperEffectCreative, SwiperPagination]"
        :slides-per-view="1"
        :pagination="{
          clickable: true,
          dynamicBullets: true,
        }"
        :effect="'creative'"
        :autoplay="{
          disableOnInteraction: true
        }"
        :creative-effect="{
          prev: {
            opacity: 0,
            shadow: false,
            translate: ['-20%', 0, 0]
          },
          next: {
            opacity: 0,
            translate: ['100%', 0, 1]
          }
        }"
        class="ui-banner__swiper"
        data-testid="elem_vrp_001053"
        @slideChange="slideChange"
      >
        <SwiperSlide
          v-for="slide in banners"
          :key="slide.id"
          :data-swiper-autoplay="slide.duration * 1000"
          :class="{ 'ui-banner__background': isShowSlideBackground(slide) }"
          class="ui-banner__slide"
          data-testid="element_000288"
          #="{ isActive, isVisible }"
          @click="onClick(slide)"
        >
          <div v-if="slide.video === null" class="ui-banner__slide-wrapper" data-testid="element_000289">
            <ui-button v-if="slide.timer > 0" class="ui-banner__status stl-text-14wb600">
              <span v-if="slide.timerLabel" class="mr2-4" data-testid="elem_vrp_000692">
                {{ slide.timerLabel + ": " }}
              </span>
              <client-only>
                <countdown :time="slide.timer" format="DD:HH:mm:ss" @finish="removeSlider(slide.id)">
                  <template #="{ resolved }">
                    <span class="ui-banner__countdown-item" data-testid="elem_vrp_000769">{{ resolved.DD }}</span> :
                    <span class="ui-banner__countdown-item" data-testid="elem_vrp_000770">{{ resolved.HH }}</span> :
                    <span class="ui-banner__countdown-item" data-testid="elem_vrp_000771">{{ resolved.mm }}</span> :
                    <span class="ui-banner__countdown-item" data-testid="elem_vrp_000772">{{ resolved.ss }}</span>
                  </template>
                </countdown>
              </client-only>
            </ui-button>
            <div class="slide-wrapper__title stl-title-32wb700 stl-color-white" data-testid="element_000290">
              {{ slide.header }}
            </div>
            <div class="slide-wrapper__text stl-text-16wb400v2 stl-color-white" data-testid="element_000291">
              {{ slide.text }}
            </div>
          </div>
          <video
            v-if="slide?.video?.path"
            :class="{
              'ui-banner__video-show': slide.video && isActive,
              'ui-banner__video-visible': isVisible,
            }"
            :key="isVisible"
            ref="video"
            autoplay
            loop
            muted
            playsinline
            class="ui-banner__video"
            :poster="slide?.imageLandscape?.path"
            data-testid="elem_vrp_001054"
          >
            <source :src="slide?.video?.path">
          </video>
          <img
            v-if="slide.imageLandscape?.path"
            :class="{ 'ui-banner__img-show': slide.video === null || !isActive }"
            :src="slide.imageLandscape.path || ''"
            :alt="slide.imageLandscape?.alt || ''"
            class="ui-banner__img"
            data-testid="element_000292"
          >
        </SwiperSlide>

        <ui-soft-page-swiper-pagination :key="activeIndex" class="ui-banner-custom-pagination" @change="slideChange" />
      </Swiper>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Countdown from 'vue3-countdown'
import apiContentAnnouncementSlider, { type IApiContentAnnouncementSliderItem } from '~/api/content/announcementSlider'

const props = defineProps({
  page: {
    type: String,
    required: true,
  }
})
const calculateTimeLeft = (banners: unknown[]) => {
  if (banners && Array.isArray(banners)) {
    return banners.map((banner) => {
      const now = Math.floor(Date.now() / 1000)
      banner.timer = Math.max(0, (banner.timerTimestamp - now) * 1000)
      return banner
    })
  }
  return banners
}

const announcementSlider = await useAsyncData('announcement-slider', async () => {
  const announcementSlider = await apiContentAnnouncementSlider()
  if (announcementSlider.data) {
    return calculateTimeLeft(announcementSlider.data)
  }
  return []
})

const { width } = useSSRWindowSize()
const type = computed(() => {
  return width.value > 599 ? 'desktop' : 'mobile'
})

const banners = computed(() => {
  // TODO: if no data
  const sliders = announcementSlider.data.value.filter(obj => obj.pages.includes(props.page) && obj.displayType === type.value) as IApiContentAnnouncementSliderItem[]
  return sliders.sort((a, b) => a.sort - b.sort)
})

const isShowSlideBackground = computed(() => {
  return (slide: IApiContentAnnouncementSliderItem) => slide.video ? false : (slide.timerTimestamp && slide.header && slide.text)
})

const removeSlider = (id: string) => {
  if (banners.value && Array.isArray(banners.value)) {
    const index = banners.value.findIndex(banner => banner.id === id)
    if (index !== -1) {
      banners.value.splice(index, 1)
    }
  }
}

const router = useRouter()

function onClick(slide) {
  if (slide?.link) {
    router.push(slide.link)
  }
}

const isForceHide = ref(false)
const nuxt = useNuxtApp()

nuxt.hook('page:start', () => {
  isForceHide.value = true
})

const activeIndex = ref(0)
function slideChange(swiper) {
  activeIndex.value = swiper.activeIndex

  const totalSlidesIndex = banners.value.length - 1
  if (activeIndex.value === totalSlidesIndex) {
    const lastSlideDuration = Number((banners.value[totalSlidesIndex].duration || 1)) * 1000
    if (swiper?.pagination) {
      setTimeout(() => {
        activeIndex.value = totalSlidesIndex
        swiper.pagination.bullets[0]?.click()
      }, lastSlideDuration)
    }
  }
}

onMounted(() => {
  isForceHide.value = false
})
</script>

<style lang="scss">
.ui-banner {
  & &__swiper {
    border-radius: 10rem;

    @include bp-tablet() {
      height: 295rem !important;
      border-radius: 0;
      margin-left: -24rem;
      margin-right: -24rem;
    }

    @include bp-mobile() {
      height: 464rem !important;
      margin-left: -12rem;
      margin-right: -12rem;
    }
  }

  & &__slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 565rem !important;
    border-radius: 10rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include bp-tablet {
      height: 274rem !important;
      border-radius: 0;
    }

    @include bp-mobile() {
      height: 443rem !important;
    }
  }

  & &__status {
    display: flex;
    min-width: 150rem;
    height: 34rem;
    padding: 6rem 8rem;
    margin-bottom: 12rem;
    background-image: linear-gradient(to right, #DD066D, #9000FF);
    cursor: default;
  }

  & &__countdown-item {
    min-width: 19rem;
    display: inline-block;
  }

  & &__img {
    position: absolute;
    width: 100%;
    height: 565rem;
    object-fit: cover;
    border-radius: 10rem;
    z-index: 1;
    @include bp-tablet {
      width: 100%;
      height: 274rem;
      border-radius: 0;
    }

    @include bp-mobile() {
      height: 443rem;
    }
  }

  & &__video {
    opacity: 0;
    position: absolute;
    display: block;
    height: inherit;
    object-fit: fill;
    width: 100%;
    z-index: 2;
    transition: opacity 1000ms;
    transition-timing-function: ease-in;

    @include  bp-mobile() {
      object-fit: cover;
    }
  }

  & &__video.ui-banner__video-visible {
    opacity: 0;
  }

  & &__video.ui-banner__video-show {
    opacity: 1;
  }

  & &__slide-wrapper {
    z-index: 2;
    position: absolute;
    left: 24rem;
    bottom: 24rem;

    @include bp-mobile() {
      left: 12rem;
      bottom: 12rem;
    }
  }

  &__background {
    &::after {
      content: '';
      position: absolute;
      height: 166rem;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
      z-index: 1;
      border-radius: 10rem;

      @include bp-tablet() {
        height: 151rem;
        border-radius: 0;
      }

      @include bp-mobile() {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 51%, rgba(0, 0, 0, 0.90) 100%);
        height: 100%;
      }
    }
  }

  & .ui-banner-custom-pagination {
    margin-top: 12rem;
  }
}
</style>
